/**
 * App
*/
import $ from "jquery";
import AOS from "../../node_modules/aos/dist/aos";
import navbar from "Components/navbar";
import 'Components/micromodal.js';
import party from "party-js";

const linkElement = document.getElementById("link");

function redirect(type) {
    let url = `https://payment.sneakytools.io/${type}`

    const urlParams = new URLSearchParams(window.location.search);
    const affiliateParam = urlParams.get('via');

    if (affiliateParam) {
        /* eslint-disable */
        url = `${url}?affiliate=${Rewardful.referral}`
    }

    document.location.href = url;
}

let businessElement = document.getElementById('business');
if (businessElement) {
    businessElement.addEventListener("click", () => {
        linkElement.href = "javascript:void(0);";

        linkElement.textContent = 'Get started for 28€';

        linkElement.onclick = function () {
            redirect('business');
        };
    });
}

let premiumElement = document.getElementById('premium');
if (premiumElement) {
    premiumElement.addEventListener("click", () => {
        linkElement.href = "javascript:void(0);";

        linkElement.textContent = 'Get started for 18€';

        linkElement.onclick = function () {
            redirect('premium');
        };
    });
}

let freeElement = document.getElementById('free');

if (freeElement) {
    document.getElementById("free").addEventListener("click", () => {
        linkElement.href = "https://buy.stripe.com/00gcOKaqz6V8ec09AA";

        linkElement.textContent = 'Get started for free';
    });
}

window.onload = function () {
    var businessCheckbox = document.getElementById('businessCheckbox');
    var licenseCheckbox = document.getElementById('licenseCheckbox');
    var link = document.getElementById('link');

    function updateLinkState() {
        if (businessCheckbox.checked && licenseCheckbox.checked) {
            link.classList.remove('disabled');  // Enable the link
        } else {
            link.classList.add('disabled');  // Disable the link
        }
    }

    businessCheckbox.addEventListener('change', updateLinkState);
    licenseCheckbox.addEventListener('change', updateLinkState);
    updateLinkState();  // Set the initial state
};


document.addEventListener('DOMContentLoaded', function () {
    window.$ = $;

    if (typeof console === 'object') {
        const flame = String.fromCodePoint(0x1F525);
        const heart = String.fromCodePoint(0x1F49C);

        console.log('%c%s',
            'color: white; background: black; font-size: 18px;',
            '\n' +
            ' Made with ' + heart + ' by Empowers Studio ' + flame + '\n' +
            ' Designed & Developed by -@EmpowersStudio \n' +
            ' Visit us => https://twitter.com/EmpowersStudio ');
    }

    AOS.init({
        offset: -210,
    });



    $('.leading-loose span').each(function () {
        $(this).click(function (j) {
            var dropDown = $(this).closest('li').find('div.leading-loose__more');

            $(this).closest('.leading-loose').find('div.leading-loose__more').not(dropDown).slideUp();


            if ($(this).hasClass('active')) {
                $(this).removeClass('active').html('<strong>+</strong> show all benefits');
            } else {
                $(this).closest('.leading-loose').find('span.active').removeClass('active').html('<strong>+</strong> show all benefits');
                $(this).addClass('active').html('<strong>-</strong> show less benefits');
            }

            dropDown.stop(false, true).slideToggle();

            j.preventDefault();
        });
    });


    // $('body').bind('cut copy paste', function(event) {
    //     event.preventDefault();
    // });

    // document.oncontextmenu = new Function("return false");

    navbar();

    $('a[href^="#"]').on('click', function (e) {
        e.preventDefault();
        var target = this.hash;
        var header = $('.header').height();
        var $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': ($target.offset().top - header)
        }, 900, 'swing', function () {
            window.location.hash = target;
        });
    });



    $('.accordion span').click(function (j) {
        var dropDown = $(this).closest('li').find('p');

        $(this).closest('.accordion').find('p').not(dropDown).slideUp();

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).closest('.accordion').find('li.active').removeClass('active');
        } else {
            $(this).closest('.accordion').find('span.active').removeClass('active');
            $(this).closest('.accordion').find('li.active').removeClass('active');

            $(this).toggleClass('active');
            $(this).parent().toggleClass('active');
        }

        dropDown.stop(false, true).slideToggle();

        j.preventDefault();
    });

    if (window.location.search.includes("success")) {
        let purchaseBtn = document.getElementById("purchaseNow")
        purchaseBtn.innerHTML = "Welcome to SneakyTools!"
        setTimeout(() => {
            party.confetti(purchaseBtn, {
                count: party.variation.range(150, 150),
                speed: party.variation.range(300, 600),
            });
        }, 2000)
    }

    'use strict';
});